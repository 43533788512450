<template>
  <div>
    <b-card class="card-accent-primary" header-tag="header">
      <b-nav tabs>
        <b-nav-item v-if="user.permissions_result && user.permissions_result.indexOf('user-settings_view') !== -1" active>
          <router-link :to="'/settings/user'">{{ $t('wdm16.3853') }}</router-link>
        </b-nav-item>
        <b-nav-item v-if="user.permissions_result && user.permissions_result.indexOf('user-settings_edit') !== -1">
          <router-link :to="'/settings/user/edit'" :exact="true">{{ $t('wdm16.3897') }}</router-link>
        </b-nav-item>
      </b-nav>
      <b-card header-tag="header">
        <div slot="header">
          <strong>{{ $t('wdm16.10984') }}</strong>
        </div>
        <profile></profile>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import Vue from 'vue';
// import { API_BASE_URL } from '@/config';
import store from '@/store';
import vSelect from 'vue-select';
import {
  default as router
} from '@/router';

export default {
  name: 'UserSettings',
  computed: {
    ...mapGetters({
      patient: 'patient_selected'
    })
  },
  mounted: function () {
    let user = localStorage.getItem('user');
    this.user = JSON.parse(user);
    store.commit('selected_patient', false);
  },
  data () {
    return {
      user: {}
    };
  },
  created: function () {},
  filters: {
    upper: function (value) {
      if (value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      }
    }
  },
  methods: {
    go_back: function () {
      router.go(-1);
    }
  },
  components: {
    vSelect,
  }
};

</script>

